// src/App.tsx
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MarketingPage from './MarketingPage';
import FormPage from './pages/FormPage';
import NotFoundPage from './pages/NotFoundPage';

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<MarketingPage />} />
        <Route path="/travesia-festival-del-ruiz-dh1" element={<FormPage />} />
        <Route path="*" element={<NotFoundPage />} /> {/* Ruta 404 */}
      </Routes>
    </Router>
  );
};

export default App;
