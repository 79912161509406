// src/components/AppAppBar.tsx
import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Sitemark from './SitemarkIcon';
import YoutubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FitnessCenter from '@mui/icons-material/FitnessCenter';
import MailCenter from '@mui/icons-material/Mail';
import { Stack } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexShrink: 0,
  borderRadius: `calc(${theme.shape.borderRadius}px + 8px)`,
  backdropFilter: 'blur(24px)',
  border: '1px solid',
  borderColor: theme.palette.divider,
  backgroundColor: alpha(theme.palette.background.default, 0.4),
  boxShadow: theme.shadows[1],
  padding: '8px 12px',
}));

export default function AppAppBar() {
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (
    newOpen: boolean | ((prevState: boolean) => boolean)
  ) => () => {
    setOpen(newOpen);
  };

  return (
    <>
      {/* Smooth scrolling */}
      <style>
        {`
          html {
            scroll-behavior: smooth;
          }
        `}
      </style>

      <AppBar
        position="fixed"
        sx={{ boxShadow: 0, bgcolor: 'transparent', backgroundImage: 'none', mt: 4 }}
      >
        <Container maxWidth="lg">
          <StyledToolbar variant="dense" disableGutters>
            <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', px: 0 }}>
              <Sitemark />
              <Box sx={{ display: { xs: 'none', md: 'flex' }, ml: 2 }}>
                {/* Enlaces a secciones internas de la página principal */}
                <Button variant="text" color="info" size="small" href="/#servicios">
                  Servicios
                </Button>
                <Button variant="text" color="info" size="small" href="/#testimonios">
                  Testimonios
                </Button>
                <Button variant="text" color="info" size="small" href="/#destacados">
                  Destacados
                </Button>
                <Button variant="text" color="info" size="small" href="/#personalizacion">
                  Personalización
                </Button>
                <Button variant="text" color="info" size="small" sx={{ minWidth: 0 }} href="/#faq">
                  FAQ
                </Button>
                {/* Enlace a la página del formulario */}
                <Button
                  variant="text"
                  color="info"
                  size="small"
                  component={RouterLink}
                  to="/travesia-festival-del-ruiz-dh1"
                  sx={{ ml: 1 }}
                >
                  FESTIVAL DEL RUIZ DH1
                </Button>
              </Box>
            </Box>
            <Box
              sx={{
                display: { xs: 'none', md: 'flex' },
                gap: 1,
                alignItems: 'center',
              }}
            >
              <Stack
                direction="row"
                spacing={1}
                useFlexGap
                sx={{ justifyContent: 'left', color: 'text.secondary' }}
              >
                <IconButton
                  color="inherit"
                  size="small"
                  href="https://instagram.com/pachoxc"
                  aria-label="Instagram"
                  sx={{ alignSelf: 'center' }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <InstagramIcon />
                </IconButton>
                <IconButton
                  color="inherit"
                  size="small"
                  href="https://www.youtube.com/@PachoXC"
                  aria-label="Youtube"
                  sx={{ alignSelf: 'center' }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <YoutubeIcon />
                </IconButton>
                <IconButton
                  color="inherit"
                  size="small"
                  href="https://api.whatsapp.com/send/?phone=573017713517&text=Hola%20Pacho!%20Vengo%20desde%20la%20pagina%20y%20estoy%20interesado%20en..."
                  aria-label="WhatsApp"
                  sx={{ alignSelf: 'center' }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <WhatsAppIcon />
                </IconButton>
                <IconButton
                  color="inherit"
                  size="small"
                  href="mailto:pacho@pachoxc.com"
                  aria-label="Correo Electronico"
                  sx={{ alignSelf: 'center' }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <MailCenter />
                </IconButton>
                <IconButton
                  color="inherit"
                  size="small"
                  href="https://www.trainingpeaks.com/coach/httpshometrainingpeakscomattachtocoachsharedkeykg56c6cl6#about"
                  aria-label="Training Peaks"
                  sx={{ alignSelf: 'center' }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FitnessCenter />
                </IconButton>
              </Stack>
            </Box>
            <Box sx={{ display: { sm: 'flex', md: 'none' } }}>
              <IconButton aria-label="Menu button" onClick={toggleDrawer(true)}>
                <MenuIcon />
              </IconButton>
              <Drawer anchor="top" open={open} onClose={toggleDrawer(false)}>
                <Box sx={{ p: 2, backgroundColor: 'background.default' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <IconButton onClick={toggleDrawer(false)}>
                      <CloseRoundedIcon />
                    </IconButton>
                  </Box>
                  <Divider sx={{ my: 3 }} />
                  {/* Enlaces en el Drawer */}
                  <MenuItem
                    onClick={toggleDrawer(false)}
                    component="a"
                    href="/#servicios"
                  >
                    Servicios
                  </MenuItem>
                  <MenuItem
                    onClick={toggleDrawer(false)}
                    component="a"
                    href="/#testimonios"
                  >
                    Testimonios
                  </MenuItem>
                  <MenuItem
                    onClick={toggleDrawer(false)}
                    component="a"
                    href="/#destacados"
                  >
                    Destacados
                  </MenuItem>
                  <MenuItem
                    onClick={toggleDrawer(false)}
                    component="a"
                    href="/#personalizacion"
                  >
                    Personalización
                  </MenuItem>
                  <MenuItem
                    onClick={toggleDrawer(false)}
                    component="a"
                    href="/#faq"
                  >
                    FAQ
                  </MenuItem>
                  {/* Enlace al Formulario en el Drawer */}
                  <MenuItem
                    onClick={toggleDrawer(false)}
                    component={RouterLink}
                    to="/travesia-festival-del-ruiz-dh1"
                  >
                    FESTIVAL DEL RUIZ DH1
                  </MenuItem>
                </Box>
              </Drawer>
            </Box>
          </StyledToolbar>
        </Container>
      </AppBar>
    </>
  );
}
