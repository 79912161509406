// src/pages/FormPage.tsx
import React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import AppAppBar from '../components/AppAppBar';
import Footer from '../components/Footer';
import getMPTheme from '../theme/getMPTheme';
import { Container, Box, Typography, Button, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const FormPage: React.FC = () => {
  const MPTheme = createTheme(getMPTheme('light'));

  return (
    <ThemeProvider theme={MPTheme}>
      <CssBaseline />
      <AppAppBar />
      <Container
        maxWidth="md"
        sx={{
          mt: 12, // Ajusta este valor según la altura de tu AppBar
          mb: 8,  // Aumenta el margen inferior para evitar superposición con el footer
        }}
      >
        {/* Título Principal */}
        <Typography variant="h4" component="h1" gutterBottom align="center">
          Inscripción al Festival del Ruiz DH1
        </Typography>

        {/* Imagen Promocional */}
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            mb: 4, // Margen inferior para separar la imagen del contenido
          }}
        >
          <img
            src="/static/images/festival-dh1.jpg" // Ruta relativa desde 'public'
            alt="Banner del Festival del Ruiz DH1"
            style={{
              maxWidth: '100%',
              height: 'auto',
              borderRadius: '8px', // Opcional: Añade bordes redondeados
            }}
          />
        </Box>

        {/* Descripción del Festival */}
        <Typography variant="body1" align="justify" paragraph>
          <strong>TRAVESIA FESTIVAL DEL RUIZ DH1</strong>
          <br />
          El festival DH1 trae una travesía de cross country maratón. 37 kilómetros de pura aventura por los bosques de pino de Manizales y Villamaría. Un reto para todos los niveles, desde aficionados hasta expertos. Un fin de semana de buena bici y buen parche. ¡¡¡No te lo pierdas!!! en alianza con Chiguiro, Rafael Gutiérrez, Felipe Gómez (el calvo) y Pachoxc.
        </Typography>

        {/* Detalles de la Inscripción */}
        <Typography variant="body1" align="justify" paragraph>
          La inscripción tendrá un valor de $120.000.
        </Typography>

        {/* Beneficios de la Inscripción */}
        <Typography variant="h6" gutterBottom>
          Tendrás derecho a:
        </Typography>
        <List>
          <ListItem>
            <ListItemIcon>
              <CheckCircleIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="Póliza para la travesía" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <CheckCircleIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="Camiseta de algodón oficial del evento" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <CheckCircleIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="Número de competencia" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <CheckCircleIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="Hidratación" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <CheckCircleIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="Acceso al Festival del Ruiz 2 días" />
          </ListItem>
        </List>

        {/* Cita Inspiradora */}
        <Typography variant="h6" align="center" sx={{ mt: 4, fontStyle: 'italic' }}>
          "Si no lo intentas, no te darás cuenta si lo puedes lograr"
        </Typography>

        {/* Botón para Rellenar el Formulario */}
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            mt: 6, // Margen superior para separar del contenido
            mb: 4, // Margen inferior para separar del footer
          }}
        >
          <Button
            variant="contained"
            color="primary"
            href="https://docs.google.com/forms/d/e/1FAIpQLSfwLNmYC11OBNtZhEGp3EGTj0A-hr3cuk6RLvmG4NJ9zugcAw/viewform?usp=sf_link"
            target="_blank"
            rel="noopener noreferrer"
            size="large"
            sx={{
              paddingX: { xs: 4, sm: 6 },
              paddingY: { xs: 1.5, sm: 2 },
              fontSize: { xs: '1rem', sm: '1.25rem' },
            }}
          >
            Realizar Inscripción
          </Button>
        </Box>
      </Container>
      <Footer />
    </ThemeProvider>
  );
};

export default FormPage;
