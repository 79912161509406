// src/pages/NotFoundPage.tsx
import React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Container, Typography, Button } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import AppAppBar from '../components/AppAppBar';
import Footer from '../components/Footer';
import getMPTheme from '../theme/getMPTheme';

const NotFoundPage: React.FC = () => {
  const MPTheme = createTheme(getMPTheme('light'));

  return (
    <ThemeProvider theme={MPTheme}>
      <AppAppBar />
      <Container maxWidth="sm" sx={{ mt: 8, textAlign: 'center' }}>
        <Typography variant="h3" component="h1" gutterBottom>
          404
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom>
          Página no encontrada
        </Typography>
        <Button variant="contained" color="primary" component={RouterLink} to="/">
          Volver al Inicio
        </Button>
      </Container>
      <Footer />
    </ThemeProvider>
  );
};

export default NotFoundPage;
